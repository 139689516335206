<template>
  <LiefengContent>
    <template #title>敏感词管理
    </template>
    <template #toolsbarRight>
      <Form
        inline
        label-colon 
        >
        <FormItem>
          <span v-solt="label">敏感词：</span>
          <Input v-model.trim="sWord" style="width: 150px"></Input>
        </FormItem>
        <FormItem>
          <span v-solt="label">分类名称：</span>
          <Input v-model.trim="sSort" style="width: 150px"></Input>
        </FormItem>
        <Button
          type="primary"
          style="margin-right: 10px"
          @click="search"
          >查询</Button
        >
        <Button
          type="success"
          style="margin-right: 10px"
          @click="reset"
          >重置</Button
        >
        <Button
          type="primary"
          style="margin-right: 10px"
          @click="addWord"
          >新增敏感词</Button
        >
        <Button
          type="primary"
          style="margin-right: 10px"
          @click="addWords"
          >批量新增</Button
        >
        <Button
          type="primary"
          style="margin-right: 10px"
          @click="importExcel"
          >导入</Button
        >
        <Button
          type="primary"
          style="margin-right: 10px"
          @click="goSort"
          >敏感词分类管理</Button
        >
        <Button
          type="error"
          style="margin-right: 10px;width:112px"
          @click="delMore"
          >批量删除({{ids.length}})</Button
        >
      </Form>
      
    </template>
    <template #contentArea>
        <LiefengTable
            :tableData="tableData"
            :talbeColumns="talbeColumns"
            :loading="loading"
            :pageSize="pageSize"
            :pagesizeOpts="[20, 30, 50, 100, 200]"
            :total="total"
            :fixTable="true"
            :curPage="page"
            @hadlePageSize="hadlePageSize"
            @tableSelect ='tableSelect'
        ></LiefengTable>
     
        <!-- 单个新增 -->
        <LiefengModal 
            title='新增敏感词'
            :value="addStatus"
            @input="changeAddStatus"
            height="200px"
            width='500px'
            >
            <template v-slot:contentarea>
                <Form
                    :label-colon="true"
                    :label-width="120"
                >
                <FormItem>
                    <span slot="label" class="validate">敏感词：</span>
                    <Input style="width:300px" v-model.trim="word.text" :maxlength="100"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">所属分类：</span>
                    <Select transfer v-model="word.catId" style="width:300px">
                        <Option v-for="item in sortList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                </Form>
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" style="marginRight:10px" @click="saveOne">保存</Button>
                <Button type="primary" style="marginRight:10px" @click="cancelOne">取消</Button>
            </template>
        </LiefengModal>

        <!-- 多个新增 -->
        <LiefengModal 
            title='批量新增'
            :value="addMoreStatus"
            @input="changeAddMoreStatus"
            height="500px"
            width='490px'
            >
            <template v-slot:title>
                <div class='mtitle'>
                    <span slot="label" class="validate">敏感词：</span>
                    <span slot="label" style="margin-left:162px" class="validate">所属分类</span>
                    <Button style="margin-left:66px" type="primary" @click="changeTextStatus(true)" >多项录入</Button>
                </div>
                
            </template> 
            <template v-slot:contentarea>
                <div class="listitem" v-for="(item,index) in words" :key="index">
                    <Input style="width:220px;margin-right:10px" v-model.trim="item.text" :maxlength="100"></Input>
                    <Select clearable v-model="item.catId" style="width:220px">
                        <Option v-for="oitem in sortList" :value="oitem.value" :key="oitem.value">{{ oitem.label }}</Option>
                    </Select>
                </div>
                
            
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" style="marginRight:10px" @click="saveMore">保存</Button>
                <Button style="marginRight:10px" @click="cancelMore">取消</Button>
            </template>
        </LiefengModal>
        
        <!-- 多项录入 -->
        <LiefengModal
                title="多项录入"
                :value="textStatus"
                @input="changeTextStatus"
                height="292px"
                width='600px'
            >
            <template v-slot:contentarea>
                <Input v-model="textContent" rows='10' type="textarea" placeholder="粘贴文本到文本域中，每行文字作为一条敏感词" />
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" style="marginRight:10px" @click="saveText">保存</Button>
                <Button style="marginRight:10px" @click="cancelText">取消</Button>
            </template>
        </LiefengModal>

        <LiefengModal
            title="导入" 
            width="40%" 
            height='230px'  
            :value="excelStatus" 
            @input="excelModalData" 
            class="export-modal"
        >
            <template v-slot:contentarea>
                <LiefengUploadExcel style="margin:20px 0" :showDownModal="{isShow:true,downLink:'./excelfiles/sensitiveWords.xlsx', downName:'敏感词导入模板'}" :headers="headers" :action="exportAction" :uploadData="uploadData" :isShow='showExcel'   @success="excelsuccess"></LiefengUploadExcel>
            </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import LiefengUploadExcel from './childrens/LiefengUploadExcel.vue'
export default {
    data() {
        return {
            tableData: [],
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "敏感词",
                    key: "text",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "所属分类",
                    key: "catName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "创建时间",
                    key: "gmtCreate",
                    minWidth: 300,
                    align: "center",
                    render:(h,params) => {
                        return h('div',{},params.row.gmtCreate ? this.$core.formatDate(
                            new Date(params.row.gmtCreate),
                            "yyyy-MM-dd hh:mm:ss"
                        ):'')
                    }
                },
                {
                    title: "操作",
                    minWidth: 300,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                        h(
                            "Button",
                            {
                            props: {
                                type: "error",
                                size: "small",
                                
                            },
                            style: {
                                marginLeft: "14px",
                            },
                            on: {
                                click: () => {
                                    this.$Modal.confirm({
                                        title: "温馨提示",
                                        content: `是否删除<span style='color:red'> ${params.row.text} </span>`,
                                        onOk: () => {
                                        this.$post('/dictlist/api/lfcommon/pc/sen/deleteSen',{
                                            id:params.row.id
                                        }).then(res => {
                                            if(res.code == 200){
                                            this.$Message.success({
                                                content:"删除成功",
                                                background:true
                                            })
                                            this.getList()
                                            }else{
                                            this.$Message.error({
                                                content:"删除失败",
                                                background:true
                                            })
                                            }
                                        })
                                        }
                                    })
                                },
                            },
                            },
                            "删除"
                        ),
                        ]);
                    },
                },
            ],
            loading: false,
            pageSize: 20,
            total: 0,
            page: 1,
            ids:[],
            //搜索条件
            sWord:'',
            sSort:'',
            // num:0,
            
            addStatus:false,
            addMoreStatus:false,
            word:{
                text:'',
                catId:''
            },
            words:[
                {
                    text:'',
                    catId:''
                },
                {
                    text:'',
                    catId:''
                },
                {
                    text:'',
                    catId:''
                },
                {
                    text:'',
                    catId:''
                },
                {
                    text:'',
                    catId:''
                },
                {
                    text:'',
                    catId:''
                },
                {
                    text:'',
                    catId:''
                },
                {
                    text:'',
                    catId:''
                },
                {
                    text:'',
                    catId:''
                },
                {
                    text:'',
                    catId:''
                }
            ],
            sortList:[],
            textContent:'',
            textStatus:false,

            // 导入
            excelStatus:false,
            headers: {
                openId: window.sessionStorage.getItem('openId'),
                Authorization:window.sessionStorage.getItem('accessToken')
            },
            exportAction:'',
            uploadData:{

            },
            showExcel:0,



        };
    },
    methods: {
        // 进去敏感词分类管理
        goSort(){
            this.$router.push("/wordssortmanage")
        }, 
        //分页
        hadlePageSize(val){
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 选框
        tableSelect(val){
            this.ids = []
            // this.num = val.length
            val.map( e=> {
                this.ids.push(e.id)
            })
        },
        // 查询
        search(){
            this.getList()
        },
        //重置
        reset(){
            this.page = 1
            this.pageSize = 20
            this.sWord = '';
            this.sSort = '';
            this.getList()
        },
        
        // 单个新增框事件
        changeAddStatus(val){
            this.addStatus = val
        },
        changeAddMoreStatus(val){
            this.addMoreStatus = val
        }, 
        addWord(){
            this.word = {
                text:'',
                catId:''
            }
            this.getsortList();
            this.addStatus = true
        },
        addWords(){
            this.words.map(e=>{
                e.text='',
                e.catId=''
            })
            this.getsortList();
            this.addMoreStatus = true
            
        },
        //获取分类列表
        getsortList(){
            this.sortList = []
            this.$get('/dictlist/api/lfcommon/pc/sen/selectSenCatAll',{
                // page:1,
                // pageSize:100
            }).then( res => {
                if(res.code == 200){
                    res.dataList.map(e => {
                        this.sortList.push({
                            label:e.catName,
                            value:e.id
                        })
                    })
                }
            })
        },
        // 获取列表
        getList(){
        this.loading = true
            this.$get('/dictlist/api/lfcommon/pc/sen/selectSenPage',{
                page:this.page,
                pageSize:this.pageSize,
                catName:this.sSort,
                text:this.sWord
            }).then( res => {
                this.loading = false
                if(res.code == 200){
                    this.tableData = res.dataList
                    this.total = res.maxCount
                }else{
                    this.$Message.error({
                        content:'获取详情失败',
                        background:true
                    })
                }
            })
        },
        // 单个保存
        saveOne(){
            if(!this.word.text.trim()){
                this.$Message.warning({
                    content:'敏感词不能为空',
                    background:true
                })
                return
            }
            if(!this.word.catId){
                this.$Message.warning({
                    content:'敏感分类不能为空',
                    background:true
                })
                return
            }
            this.$post('/dictlist/api/lfcommon/pc/sen/addSen',{
                text:this.word.text,
                catId:this.word.catId
            }).then( res => {
                if(res.code == 200){
                    this.$Message.success({
                        content:'新增成功',
                        background:true
                    })
                    this.addStatus = false
                    this.getList()
                }
            })
            
        },
        // 单个关闭
        cancelOne(){
            this.addStatus = false
            this.word = {
                text:'',
                catId:''
            }
        },
        // 多个保存
        saveMore(){
            let num = 1
            this.words.map( e => {
                if(e.text.trim() || e.catId){
                    num = 0
                }
            })
            if(num){
                this.$Message.warning({
                    content:'敏感词不能为空',
                    background:true
                })
                return
            }
            let numo = 0
            for(let i=0;i<this.words.length-1;i++){
                if(this.words[i].text.trim() && !this.words[i].catId){
                    numo = 1
                    this.$Message.warning({
                        content:'敏感词分类不能为空',
                        background:true
                    })
                    break
                }else if(!this.words[i].text.trim() && this.words[i].catId){
                    numo = 1
                    this.$Message.warning({
                        content:'敏感词不能为空',
                        background:true
                    })
                    break
                }
            }
            if(numo) return
            let wordArr = []
            this.words.map( e => {
                if(e.text){
                    wordArr.push({
                        text:e.text,
                        catId:e.catId,
                    })
                }
            })
            this.$post('/dictlist/api/lfcommon/pc/sen/addSenList',
            [...wordArr] 
            ,
            { "Content-Type": "application/json" }
            ).then(res => {
                if(res.code == 200){
                    this.$Message.success({
                        content:'新增成功',
                        background:true
                    })
                    this.getList()
                    this.addMoreStatus = false
                }else{
                    this.$Message.error({
                        content:'新增失败',
                        background:true
                    })
                }
            })
        },
        // 多个关闭
        cancelMore(){
            this.addMoreStatus = false
        },
        // 多项录入
        changeTextStatus(val){
            this.textStatus = val
        },
        saveText(){
            let arr = this.textContent.split('\n').slice(0,10)
            arr.map((itme,index) => {
                this.words[index].text = itme
            })
            this.textStatus = false
        },
        cancelText(){
            this.textStatus = false
        },
        // 批量删除
        delMore(){
            if(this.ids.length == 0){
                this.$Message.warning({
                    content:'请勾选数据',
                    background:true
                })
                return
            }
            this.$Modal.confirm({
                title: "温馨提示",
                content: `是否删除勾选数据`,
                onOk: () => {
                this.$post('/dictlist/api/lfcommon/pc/sen/deleteSens',{
                    ids:this.ids.join()
                }).then(res => {
                    if(res.code == 200){
                        this.$Message.success({
                            content:"删除成功",
                            background:true
                        })
                        this.getList()
                        this.ids = []
                    }else{
                        this.$Message.error({
                            content:"删除失败",
                            background:true
                        })
                    }
                })
                }
            })
        },
        importExcel(){
            this.excelStatus = true
            this.showExcel++
            this.exportAction =  window.vue.getProxy()["/dictlist"].target + "/api/lfcommon/pc/sen/importSens";
        },
        //修改excel导入弹窗状态
        excelModalData(val) {
        this.excelStatus = val;
        // this.showExcel = false

        },
        // 导入成功回调事件
        excelsuccess(){
        this.excelStatus = false
        this.reset()
        },
   
    },
    created() {
        this.getList()
    },
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
        LiefengUploadExcel
    },
};
</script>
    
<style scoped lang='less'>
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.mtitle{
    // position: relative;
    // display: flex;
    // justify-content: space-between;
    margin: 10px 20px 0 20px;
    // button{
    //     position: absolute;
    //     right: 0;
    //     top: 0;
    // }
}
.listitem{
    margin: 0 10px 10px 10px ;
}
</style>